import * as React from "react";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import EventiGrid from "../../components/EventiGrid/EventiGrid";

export default function EventiResProfessionePage({ data }) {
  const {
    provinceJson: provincia,
    allEventiRes: { nodes: eventiRes },
    totaleEventi: { totalCount },
  } = data;

  return (
    <Layout
      pageTitle={`Corsi ECM RES a ${provincia.descrizione}`}
      description={`Scegli tra i ${totalCount} corsi ECM RES disponibili che si svolgono a ${provincia.descrizione}.`}
    >
      <section className="position-relative">
        <StaticImage
          src="../../images/header-provider.jpg"
          className="img-header-home"
          alt={`Professione ${provincia.descrizione}`}
          title={`Professione ${provincia.descrizione}`}
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1 className="text-center text-white display-4 font-weight-bold">
              CORSI ECM RES per {provincia.descrizione}
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Scegli tra i {totalCount} corsi ECM RES disponibili che si
              svolgono a {provincia.descrizione}.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri i corsi
            </a>
          </div>
        </div>
      </section>
      <div id="sezione-corsi" className="container-fluid">
        <div className="row px-5 py-3">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Corsi ECM RES che si svolgono a {provincia.descrizione} in
              evidenza
            </h2>
          </div>
        </div>
      </div>
      <EventiGrid eventi={eventiRes} tipologia="RES" />
    </Layout>
  );
}

export const query = graphql`
  query ($descrizione: String = "") {
    provinceJson(descrizione: { eq: $descrizione }) {
      id
      descrizione
    }
    totaleEventi: allEventiJson(
      filter: {
        tipo_evento: { eq: "RES" }
        res_svolgimento_provincia: { eq: $descrizione }
      }
    ) {
      totalCount
    }
    allEventiRes: allEventiJson(
      limit: 100
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "RES" }
        res_svolgimento_provincia: { eq: $descrizione }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;
